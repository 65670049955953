import { AttackVector, TroopCard } from "../types";

const API_URL = process.env.NODE_ENV === 'development' ? 'https://localhost:5001' : 'https://turfwar-api.mightybot.co.uk';

export function login(fragment: string) {
    return fetch(`${API_URL}/account/login`, {
        method: "POST",
        headers: {
            "content-type": "application/json"
        },
        body: JSON.stringify({ fragment: fragment })
    }).then(result => result.json())
}

function apiPost(token: string, path: string, body?: any) {
    const json = body ? JSON.stringify(body) : undefined;
    return fetch(`${API_URL}/${path}`, {
        method: "POST",
        headers: {
            "content-type": "application/json",
            "x-authorization-token": token
        },
        body: json
    }).then(result => result.json())
}

function apiGet(token: string, path: string) {
    return fetch(`${API_URL}/${path}`, {
        method: "GET",
        headers: {
            "content-type": "application/json",
            "x-authorization-token": token
        },
    }).then(result => result.json())
}

export function loadProfile(token: string) {
    return apiPost(token, 'account/profile')
}

export function collectElixir(token: string) {
    return apiGet(token, 'account/elixir')
}

export function getMap(token: string) {
    return apiGet(token, 'turfwar/map')
}

export function deployTroops(token: string, target: AttackVector, elixir: number, troops: TroopCard[]) {
    return apiPost(token, 'turfwar/troops', { target: target, elixir: elixir, troops: troops })
}