import React from 'react';
import './App.css';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Verify } from './Verify';
import { War } from './War';

const APP_URL = process.env.NODE_ENV === 'development' ? 'https://localhost:3000' : 'https://turfwar.mightybot.co.uk';

function PathChooser(a: string, b: string, choice: () => boolean) {
  if (choice()) return <Navigate to={a}/>
  return <Navigate to={b}/>
}

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="war" element={<War />} />
        <Route path="login" element={<div className="App">
          <a href={`https://discordapp.com/api/oauth2/authorize?client_id=1087737431505252412&redirect_uri=${APP_URL}/verify&response_type=token&scope=identify`}>
            Login with Discord
          </a>
        </div>} />
        <Route path="verify" element={<Verify />} />
        <Route path="*" element={PathChooser("/login", "/war", () => !localStorage.getItem('jwt'))} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
