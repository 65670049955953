import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { login } from './api/api';

export function Verify() {
  const [discordId, setDiscordId] = useState<string>();
  const location = useLocation();
  const hash = location.hash;

  useEffect(() => {
    if (hash && hash.length && !discordId) {
      login(hash).then(user => {
        localStorage.setItem("jwt", user.token);
        setDiscordId(user.id);
      });
    }
  }, [hash, discordId]);

  if (hash && hash.length) {
    if (discordId)
      return <Navigate to="/war" />;

    return <div>Fetching Details...</div>;
  }
  else {
    return <Navigate to="/login" />;
  }
}
