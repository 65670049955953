import { useState } from 'react';
import { AttackVector, TroopCard } from './types';
import { TroopSelector } from './TroopSelector';

export function TroopModal(props: { troops: TroopCard[]; target: AttackVector; close: () => void; sendTroops: (elixir: number, troops: TroopCard[]) => void; }) {
    const elixirValues: { cost: number; boost: number; }[] = [
        { cost: 0, boost: 0 },
        { cost: 30, boost: 0.2 },
        { cost: 70, boost: 0.2 },
        { cost: 100, boost: 0.3 },
        { cost: 150, boost: 0.3 },
        { cost: 250, boost: 0.3 },
        { cost: 400, boost: 0.3 },
        { cost: 600, boost: 0.3 },
        { cost: 700, boost: 0.4 },
        { cost: 850, boost: 0.4 },
        { cost: 850, boost: 0.4 },
        { cost: 850, boost: 0.4 },
        { cost: 850, boost: 0.5 },
        { cost: 850, boost: 0.5 },
        { cost: 850, boost: 0.5 },
        { cost: 850, boost: 0.5 },
        { cost: 850, boost: 0.6 },
        { cost: 900, boost: 0.6 },
        { cost: 1000, boost: 0.6 },
        { cost: 2000, boost: 0.6 },
        { cost: 2000, boost: 0.7 },
        { cost: 2000, boost: 0.7 },
        { cost: 2000, boost: 0.7 },
        { cost: 3000, boost: 0.8 },
        { cost: 3000, boost: 0.8 },
        { cost: 5000, boost: 0.8 },
        { cost: 7000, boost: 0.9 },
        { cost: 9000, boost: 0.9 },
        { cost: 11000, boost: 0.9 },
        { cost: 13000, boost: 0.9 },
        { cost: 15000, boost: 1.0 },
        { cost: 20000, boost: 1.0 },
        { cost: 25000, boost: 1.0 },
        { cost: 30000, boost: 1.0 },
    ];
    const [elixirLevel, setElixirLevel] = useState<number>(0);
    const [elixirValue, setElixirValue] = useState<{ cost: number; boost: number; }>({ cost: 0, boost: 0 });
    const [selectedTroops, setSelectedTroops] = useState<TroopCard[]>([]);

    function calculateElixir(level: number) {
        const totalElixir = elixirValues.reduce((previous, current, index) => {
            if (level >= index)
                return { cost: previous.cost + current.cost, boost: previous.boost + current.boost };
            return previous;
        });
        setElixirValue(totalElixir);
        setElixirLevel(level);
    }

    return <div className='troop-modal'>
        <TroopSelector troops={props.troops} onChange={t => setSelectedTroops(t)} />
        <div className='selector-controls'>
            <input type='range' min={0} max={33} value={elixirLevel} onChange={(e) => { calculateElixir(parseInt(e.currentTarget.value)); }}></input>
            {elixirValue?.cost} {elixirValue?.boost}x {(elixirValue?.boost + 1) * selectedTroops.reduce((t, s) => t + s.troops, 0)}
            <div className='selector-buttons'>
                <button onClick={() => { props.sendTroops(elixirValue?.cost, selectedTroops); }}>{props.target.sourceCoordinate === props.target.targetCoordinate ? 'Defend' : 'Attack'}</button>
                <button onClick={() => { props.close(); }}>Cancel</button>
            </div>
        </div>
    </div>;
}
