import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { loadProfile, collectElixir } from './api/api';
import { TroopSelector } from './TroopSelector';
import { TroopCard, UserProfile } from './types';
import './war.scss';
import { Map } from './Map';

export function War() {
  const [profile, setProfile] = useState<UserProfile>();
  const [loggedIn, setNotLoggedIn] = useState<boolean>(false);
  const [jwtToken, setJwtToken] = useState<string>('');

  useEffect(() => {
    const token = localStorage.getItem('jwt');
    if (token) {
      setJwtToken(token);
      console.log("Loading Profile")
      loadProfile(token).then(p => {
        if (p.status === 401)
          setNotLoggedIn(true);
        else
          setProfile(p)
      });
    }
    else
      setNotLoggedIn(true);
  }, [])

  if (loggedIn)
    return <Navigate to='/login' />

  if (!profile)
    return <div>Loading...</div>

  async function collectElixirClick() {
    const elixirProfile: UserProfile = await collectElixir(jwtToken!);
    if (profile && profile.elixir) {
      setProfile(p => {
        if (p)
          return { ...p, elixir: elixirProfile.elixir };
      });
    }
  }

  return <div className='war'>
    <Map jwtToken={jwtToken} profile={profile} />
    <div className='profile'>
      <div className='display-item'><label className='display-label'>Name</label><span className='display-value'>{profile.name}</span></div>
      <div className='display-item'><label className='display-label'>Elixir</label><span className='display-value'>{profile.elixir}</span><button onClick={collectElixirClick}>+</button></div>
    </div>
  </div>
}
