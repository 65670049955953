import { useCallback, useEffect, useRef, useState } from 'react';
import './map.scss';
import { AttackVector, MapTile, TroopCard, TurfwarMap, UserProfile } from './types';
import { deployTroops, getMap } from './api/api';
import { Arrow } from './Arrow';
import { TroopModal } from './TroopModal';

export type MapProps = {
    jwtToken: string,
    profile: UserProfile
}



export function Map(props: MapProps): JSX.Element {
    const [tiles, setTiles] = useState<MapTile[][]>();
    const [map, setMap] = useState<TurfwarMap>();
    const [activeTiles, setActiveTiles] = useState<MapTile[]>([]);
    const [activeTargets, setActiveTargets] = useState<AttackVector[]>([]);
    const [entryCamps, setEntryCamps] = useState<MapTile[]>([]);
    const [activeAttack, setActiveAttack] = useState<AttackVector>();
    const [troopTarget, setTroopTarget] = useState<AttackVector>();

    const foo = useRef<{ [coordinate: string]: DOMRect }>({});

    const measuredRef = useCallback((node: any, tile: MapTile) => {
        if (node !== null) { foo.current = ({ ...foo.current, [tile.coordinates]: node.getBoundingClientRect() }); }
    }, []);

    useEffect(() => {
        getMap(props.jwtToken).then((m: TurfwarMap) => {
            let row: MapTile[] = [];
            let activeTiles: MapTile[] = [];
            const entryCamps: MapTile[] = [];
            let f = '';
            const tiles: MapTile[][] = [];
            for (const tile of m.tiles) {

                if (tile.coordinates.match("camp")) {
                    entryCamps.push(tile);
                } else {
                    if (tile.coordinates[0] !== f) {
                        if (row && row.length) {
                            tiles.push(row);
                            row = [];
                        }
                        f = tile.coordinates[0];
                    }
                    if (tile.currentOwner)
                        console.log("TILE", tile.coordinates, tile.currentOwner, props.profile.guild)
                    if (tile.currentOwner === props.profile.guild) {
                        activeTiles.push(tile);
                    }
                    row.push(tile);
                }
            }
            tiles.push(row);
            setActiveTiles(activeTiles);
            if (!activeTiles.length) {
                setEntryCamps(entryCamps);
                setActiveTiles(entryCamps);
            }
            setTiles(tiles);
            setMap(m);
        })
    }, [])

    if (!map || !tiles)
        return <div>Loading Map...</div>

    function getTileClass(tile: MapTile): string {
        const active = activeTiles.some(a => a.coordinates === tile.coordinates);
        const a = active ? 'owned' : '';
        const target = activeTargets.some(a => a.targetCoordinate === tile.coordinates);
        const t = target ? 'target' : '';
        return `map-tile tile-${tile.coordinates} owner-${tile.currentOwner} ${a} ${t}`;
    }

    function tileClick(tile: MapTile) {
        if (activeTiles.some(a => a.coordinates === tile.coordinates))
            setActiveTargets(tile.attackVectors.map(av => ({ ...av, sourceCoordinate: tile.coordinates })));
        setTroopTarget(undefined)
        setActiveAttack(undefined);
    }

    function sendTroops(target: AttackVector, elixir: number, troops: TroopCard[]) {
        setTroopTarget(undefined);
        deployTroops(props.jwtToken, target, elixir, troops);
    }

    return <div className='map'>
        <div className='entry-camps'>
            {entryCamps.map(c => <div key={c.coordinates} className={`camp ${c.coordinates}`}><div ref={(n) => measuredRef(n, c)} onClick={() => tileClick(c)} className='camp-marker owned'></div></div>)}
        </div>
        <div className="map-tiles">
            {tiles.map((row,i) =>
                <div key={i} className='map-tile-row'>
                    {row.map(mt =>
                        <div key={mt.coordinates} ref={(n) => measuredRef(n, mt)} onClick={() => tileClick(mt)} className={getTileClass(mt)}>{mt.coordinates}</div>
                    )}
                </div>)}
        </div>
        <div className='attackOptions'>
            {activeTargets.map(x => {
                const source: DOMRect = foo.current[x.sourceCoordinate];
                const destination: DOMRect = foo.current[x.targetCoordinate];
                console.log("Attack", x, source, destination)
                const sx = source.x + source.width / 2;
                const sy = source.y + source.height / 2;
                const dx = destination.x + destination.width / 2;
                const dy = destination.y + destination.height / 2;
                const hyp = Math.sqrt((sx - dx) * (sx - dx) + (sy - dy) * (sy - dy));
                const angle = Math.atan2((dy - sy), (dx - sx));
                function selectAttack(x: AttackVector): void {
                    setTroopTarget(x);
                    setActiveAttack(x);
                }

                return <Arrow key={`${x.sourceCoordinate}=>${x.targetCoordinate}`} onClick={() => selectAttack(x)} className={activeAttack === x ? 'active attack-vector' : 'attack-vector'} style={{ rotate: angle + "rad", width: hyp, height: "60px", left: sx, top: sy - 30 }} />
            })}
        </div>
        {troopTarget ? <div className='modal-background'>
            <div className='modal'>
                <TroopModal troops={props.profile.troops} target={troopTarget} close={() => setTroopTarget(undefined)} sendTroops={(elixir, troops) => { sendTroops(troopTarget, elixir, troops) }} />
            </div>
        </div> : null}
    </div>
}
