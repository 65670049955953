import { TroopCard } from "./types";
import './troops.scss';
import { useEffect, useState } from "react";

interface SelectectableTroopCard extends TroopCard {
    selected?: boolean
}

export function TroopSelector(props: { troops: SelectectableTroopCard[], onChange: (selected: TroopCard[]) => void }): JSX.Element {
    const [selectedTroops, setSelectedTroops] = useState<TroopCard[]>([]);

    useEffect(() => { 
        props.onChange(selectedTroops);
    }, [props, selectedTroops])

    function toggleCard(card: SelectectableTroopCard) {
        setSelectedTroops(selected => {
            if (selected.some(s => s.troopId === card.troopId)) {
                card.selected = false;
                return selected.filter(s => s.troopId !== card.troopId)
            } else {
                card.selected = true;
                return [...selected, card];
            }
        })
    }

    function getClassName(t: SelectectableTroopCard): string {
        if (t.selected)
            return `troop-id-${t.troopId} troop-card selected`;
        return `troop-id-${t.troopId} troop-card`;
    }

    function selectall() {
        for (const card of props.troops) {
            card.selected = true;
        }
        setSelectedTroops(props.troops);
    }

    return <div className='troop-selector'>
        <div><button onClick={selectall}>Select All</button></div>
        <div className="selected-troops">
            <div className="troop-cards">
                {props.troops.map(t => <div style={{ backgroundImage: `url('/cards/${t.troopId}.png')` }} data-id={t.troopId} onClick={() => toggleCard(t)} className={getClassName(t)}><span className="troops">{t.troops}</span></div>)}
            </div>
        </div>
    </div>
}