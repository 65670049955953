import { CSSProperties, MouseEventHandler } from "react";

export interface ArrowProps {
    onClick: MouseEventHandler<SVGPathElement>,
    className: string,
    style: CSSProperties
}
export function Arrow(props: ArrowProps): JSX.Element {
    return <svg viewBox="0 20 70 60" preserveAspectRatio="none" style={props.style} className={props.className} fill='black' pointerEvents='none'>
        <path onClick={props.onClick} d="M 0 50 C 20 40, 40 40, 50 40 L45 20 L70 50 L45 80 L50 60 C 60 60 20 60 0 50Z" overflow="hidden" pointerEvents='painted' />
    </svg>;
}
